import { JobStatus } from "@alphafold/types";
import { LdBadge, LdIcon } from "@emdgroup-liquid/liquid/dist/react";
import React from "react";

import { ReactComponent as Hourglass } from "assets/static/images/icons/icon-hourglass.svg";
import { ReactComponent as HourglassStart } from "assets/static/images/icons/icon-hourglass-start.svg";
import { ReactComponent as HourglassHalf } from "assets/static/images/icons/icon-hourglass-half.svg";
import { ReactComponent as HourglassEnd } from "assets/static/images/icons/icon-hourglass-end.svg";
import { ReactComponent as Pen } from "assets/static/images/icons/icon-pen.svg";
import { ReactComponent as Checkmark } from "assets/static/images/icons/icon-checkmark.svg";
import { ReactComponent as Play } from "assets/static/images/icons/icon-play.svg";
import { ReactComponent as Exclamationmark } from "assets/static/images/icons/icon-exclamation.svg";

import "./styles.scss";

interface Props extends React.HTMLProps<HTMLDivElement> {
  status?: string;
}

// const statusColor = (status?: string) => {
//   switch (status) {
//     case JobStatus.error:
//       return "text-rich-red";
//     case JobStatus.creating:
//     case JobStatus.queued:
//     case JobStatus.running:
//       return "text-vibrant-yellow";
//     case JobStatus.finished:
//       return "text-vibrant-green";
//     default:
//       return "";
//   }
// };

const statusIcon = (status?: string) => {
  switch (status) {
    case JobStatus.error:
    case JobStatus.failed:
      return (
        <LdIcon>
          <Exclamationmark />
        </LdIcon>
      );
    case JobStatus.creating:
      return (
        <LdIcon>
          <Pen />
        </LdIcon>
      );
    case JobStatus.created:
    case JobStatus.queued:
    case JobStatus.submitted:
      return (
        <LdIcon>
          <Hourglass />
        </LdIcon>
      );
    case JobStatus.pending:
      return (
        <LdIcon>
          <HourglassStart />
        </LdIcon>
      );
    case JobStatus.runnable:
      return (
        <LdIcon>
          <HourglassHalf />
        </LdIcon>
      );
    case JobStatus.starting:
      return (
        <LdIcon>
          <HourglassEnd />
        </LdIcon>
      );
    case JobStatus.running:
      return (
        <LdIcon>
          <Play />
        </LdIcon>
      );
    case JobStatus.finished:
    case JobStatus.succeeded:
      return (
        <LdIcon>
          <Checkmark />
        </LdIcon>
      );
    default:
      return "";
  }
};

const JobStatusBadge: React.FC<Props> = ({ status, ...rest }) => {
  // const isBusy =
  //   status &&
  //   [
  //     JobStatus.creating.toString(),
  //     JobStatus.queued.toString(),
  //     JobStatus.running.toString(),
  //   ].indexOf(status) > -1;
  return (
    <div {...rest}>
      <LdBadge size="lg" className={`ld-badge--${status}`}>
        {statusIcon(status)}
      </LdBadge>
    </div>
    // <div
    //   className={`badge flex items-center rounded-l px-ld-8 ${statusColor(
    //     status
    //   )}`}
    //   {...rest}
    // >
    //   {isBusy && <LdLoading neutral className="ld-loading-black mr-ld-4" />}
    //   <LdTypo
    //     variant="body-xs"
    //     className={`${status === "error" && "text-white"}`}
    //   >
    //     {status}
    //   </LdTypo>
    // </div>
  );
};

export default JobStatusBadge;
