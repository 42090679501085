import React from "react";
import { LdTypo } from "@emdgroup-liquid/liquid/dist/react";

import { ComponentProps } from "types/app";
import { File } from "@alphafold/types";
import FileInfo from "components/FileInfo";

interface Props extends ComponentProps {
  files: File[];
  labelExtractor?: (file: File) => string | undefined;
  onFileDownload?: (file: File) => void;
  onFilePreview?: (file: File) => void;
}

const FileList: React.FC<Props> = ({
  files,
  labelExtractor,
  onFileDownload,
  onFilePreview,
}) => {
  return (
    <table className="flex w-full flex-col divide-y divide-sensitive-grey-darker">
      <tbody className="divide-y divide-sensitive-grey-darkest">
        {files.map((file) => (
          <tr key={file.downloadUrl}>
            {labelExtractor && (
              <td className="py-ld-16">
                <LdTypo variant="label-s">{labelExtractor(file)}</LdTypo>
              </td>
            )}
            <td className="py-ld-16">
              <FileInfo
                file={file}
                onFileDownload={onFileDownload}
                onFilePreview={onFilePreview}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default FileList;
