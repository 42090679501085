import assert from "assert";
import { BugFormData, Feedback } from "types/app";
import { blobToBase64 } from "util/files";
import { post } from "util/api";

export const useFeedback = () => {
  const sendFeedback = async (feedback: Feedback) => {
    return post("/feedback", feedback);
  };

  const sendBug = async ({
    title,
    stepsToReproduce,
    expectedResult,
    systemInformation,
    attachment,
  }: BugFormData) => {
    assert(title);
    assert(stepsToReproduce);
    assert(expectedResult);
    const data: Record<string, string | undefined> = {
      title,
      stepsToReproduce,
      expectedResult: expectedResult,
      systemInformation,
    };
    if (attachment) {
      data["attachmentName"] = attachment.name;
      data["attachment"] = await blobToBase64(attachment);
    }

    return post("/bug", data);
  };

  return { sendFeedback, sendBug };
};
