import React, { useEffect, useState } from "react";
import axios from "axios";
import { LdLink, LdTypo } from "@emdgroup-liquid/liquid/dist/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeIgnore from "rehype-ignore";
// import remarkIgnore from "remark-ignore";

import { Code, vs2015 } from "react-code-blocks";

import Card from "components/Card";

import README from "assets/data/user-guide/README.md";

const UserGuideMarkdown = () => {
  const [markdown, setMarkdown] = useState<string>();

  useEffect(() => {
    axios.get(README).then((res) => setMarkdown(res.data));
  }, []);

  return (
    <Card>
      <div className="flex w-full flex-col items-start gap-ld-8 text-justify">
        {markdown !== undefined && (
          <ReactMarkdown
            components={{
              h1: ({ children }) => (
                <LdTypo variant="h2" className="text-justify w-full">
                  {children}
                </LdTypo>
              ),
              h2: ({ children }) => (
                <LdTypo variant="h3" className="text-justify w-full">
                  {children}
                </LdTypo>
              ),
              h3: ({ children }) => (
                <LdTypo variant="h4" className="text-justify w-full">
                  {children}
                </LdTypo>
              ),
              code: ({ children }) => <Code text={children} theme={vs2015} />,
              ol: ({ ...props }) => (
                <ol className="list-decimal list-inside" {...props} />
              ),
              a: ({ children, href }) => (
                <LdLink href={href} target="_blank">
                  {children}
                </LdLink>
              ),
            }}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeIgnore]}
          >
            {markdown}
          </ReactMarkdown>
        )}
      </div>
    </Card>
  );
};

export default UserGuideMarkdown;
