// eslint-disable-next-line import/named
import moment, { MomentInput } from "moment";

export const getWeek = (date: MomentInput) => {
  const d = moment(date);
  const dayOfWeek = d.day();
  const week = d.week();
  return `CW ${dayOfWeek > 4 ? week + 1 : week}`;
};

export const getMonth = (date: MomentInput) => {
  const d = moment(date);
  const dayOfMonth = d.date();
  const month = dayOfMonth > 15 ? d.month() + 1 : d.month();
  return moment().month(month).format("MMM");
};

export const formatDuration = (duration: moment.Duration) => {
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const timeUnits = {
    hours,
    minutes,
    seconds,
  };
  const durationString = Object.entries(timeUnits)
    .filter((entry) => entry[1] > 0)
    .map((entry) => `${entry[1]} ${entry[0]}`)
    .join(" ");
  return {
    hours,
    minutes,
    seconds,
    durationString,
  };
};
