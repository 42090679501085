import React from "react";
import { LdIcon, LdTypo } from "@emdgroup-liquid/liquid/dist/react";
import { Link } from "react-router-dom";

interface Props {
  jobId?: string;
  jobName?: string;
}

const Breadcrumbs: React.FC<Props> = ({ jobId, jobName }) => {
  return (
    <div className="w-full px-ld-8 my-ld-16">
      <div className="flex justify-start items-center gap-ld-4 pt-ld-4">
        <Link to={`/jobs`} className="flex hover:underline">
          <LdTypo variant="body-s">My Jobs</LdTypo>
        </Link>
        <LdIcon name="arrow-right" size="sm" />
        <Link to={`/jobs/${jobId}`} className="flex hover:underline">
          <LdTypo variant="body-s">{jobName || jobId}</LdTypo>
        </Link>
      </div>
    </div>
  );
};

export { Breadcrumbs as default };
