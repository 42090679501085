import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";
import {
  LdButton,
  LdIcon,
  LdLoading,
  LdOption,
  LdSelect,
  LdTypo,
} from "@emdgroup-liquid/liquid/dist/react";

import { useDispatch, useSelector } from "store";
import {
  deleteJob,
  getResultArchive,
  listInputFiles,
  listJobs,
  listResultFiles,
  listResultImages,
  listTemplateFiles,
  refreshJobs,
  selectInputFiles,
  selectJob,
  selectResultArchive,
  selectResultFiles,
  selectResultImages,
  selectTemplateFiles,
} from "services/jobs";

import AppLayout from "layout/AppLayout";
import DeleteJobModal from "components/Modals/DeleteJobModal";
import Card from "components/Card";

import FileList from "./components/FileList";
import JobDetails from "./components/JobDetails";
import ProteinList from "./components/ProteinList";
import Breadcrumbs from "./components/Breadcrumbs";

import assert from "assert";
import { StateStatus } from "types/app";
import AuditTrailList from "./components/AuditTrailList";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
// import _ from "lodash";
import ProteinViewerModal from "components/Modals/ProteinViewerModal";
import { File, JobStatus } from "@alphafold/types";
import ResultsList from "./components/ResultsList";
import { sanatizeString } from "util/files";

const DOCUMENT_TITLE = "Job Details";

const Job = () => {
  const { jobId = "" } = useParams();
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const job = useSelector(selectJob(jobId));
  const inputFiles = useSelector(selectInputFiles)(jobId);
  const templateFiles = useSelector(selectTemplateFiles)(jobId);
  const resultFiles = useSelector(selectResultFiles)(jobId);
  const resultImages = useSelector(selectResultImages)(jobId);
  const resultArchive = useSelector(selectResultArchive)(jobId);
  const { status } = useSelector((state) => state.jobs);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [refreshTimer, setRefreshTimer] = useState<NodeJS.Timer>();
  const [isJobLoading, setIsJobLoading] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState<string>();

  const isLoading = useMemo(
    () => status === StateStatus.loading || isJobLoading,
    [status, isJobLoading]
  );

  const { trackPageView, trackEvent } = useMatomo();

  const deleteTimer = useCallback(() => {
    clearInterval(refreshTimer);
  }, [refreshTimer]);

  useEffect(() => {
    trackPageView({
      documentTitle: DOCUMENT_TITLE,
    });
  }, [trackPageView]);

  useEffect(() => {
    if (jobId !== "") {
      setIsJobLoading(true);
      dispatch(listJobs());
      Promise.all([
        dispatch(listInputFiles(jobId)),
        dispatch(listTemplateFiles(jobId)),
        dispatch(listResultFiles(jobId)),
        dispatch(listResultImages(jobId)),
        dispatch(getResultArchive(jobId)),
      ]).finally(() => setIsJobLoading(false));
    }
  }, [jobId]);

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(refreshJobs());
    }, 30000);
    setRefreshTimer((state) => {
      clearInterval(state);
      return timer;
    });
    () => deleteTimer();
  }, []);

  useEffect(() => {
    if (!selectedSequence && job.proteins?.length) {
      setSelectedSequence(job.proteins[0].proteinName);
    }
  }, [job.proteins]);

  const handleDeleteClicked = () => {
    setModalOpen(true);
  };

  const handleDeleteJob = () => {
    assert(job.jobId);
    trackEvent({
      category: "job-details",
      action: "delete-job",
      name: job.jobId,
      documentTitle: DOCUMENT_TITLE,
      customDimensions: [
        {
          id: 1,
          value: "job-details",
        },
      ],
    });
    dispatch(deleteJob(job.jobId));
    setModalOpen(false);
  };

  const coverageImage = useMemo(() => {
    if (selectedSequence) {
      return resultImages[sanatizeString(selectedSequence)]?.find((file) => {
        return file.fileName?.endsWith("coverage.png");
      });
    }
  }, [selectedSequence, resultImages]);

  const rankingImage = useMemo(() => {
    if (selectedSequence) {
      return resultImages[sanatizeString(selectedSequence)]?.find((file) => {
        return file.fileName?.endsWith("pae.png");
      });
    }
  }, [selectedSequence, resultImages]);

  const plddtImage = useMemo(() => {
    if (selectedSequence) {
      return resultImages[sanatizeString(selectedSequence)]?.find((file) => {
        return file.fileName?.endsWith("plddt.png");
      });
    }
  }, [selectedSequence, resultImages]);

  return (
    <AppLayout>
      {isLoading && <LinearProgress color="secondary" />}
      <div className="relative flex max-w-[1024px] mx-auto">
        <Breadcrumbs jobId={job.jobId} jobName={job?.jobName} />
      </div>
      <div
        id="job-details"
        className="relative flex-col flex max-w-[1024px] mx-auto gap-ld-16 justify-center"
      >
        <div className="relative flex flex-col items-start w-full gap-ld-16">
          <Card className="w-full gap-ld-8">
            <JobDetails job={job} />
            <div className="flex w-full flex-row justify-end gap-ld-8">
              <LdButton
                size="sm"
                mode="danger"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClicked();
                }}
              >
                Delete Job
              </LdButton>
            </div>
          </Card>
          <Card className="w-full">
            <div className="flex flex-col justify-start items-start w-full gap-ld-8">
              <LdTypo variant="h5">Input</LdTypo>
              {job.proteins !== undefined && (
                <ProteinList title="Proteins" proteins={job.proteins} />
              )}
              <hr className="bg-black h-0 border-sensitive-grey-darkest w-full" />
              <LdTypo variant="label-s">Files</LdTypo>
              <FileList
                files={inputFiles}
                onFileDownload={(file) => {
                  trackEvent({
                    category: "job-details",
                    action: "download-input",
                    name: file.fileName,
                    documentTitle: DOCUMENT_TITLE,
                    customDimensions: [
                      {
                        id: 1,
                        value: "job-details",
                      },
                    ],
                  });
                }}
              />
              {templateFiles.length > 0 && (
                <>
                  <hr className="bg-black h-0 border-sensitive-grey-darkest w-full" />
                  <LdTypo variant="h5">Templates</LdTypo>
                  <FileList
                    files={templateFiles}
                    onFileDownload={(file) => {
                      trackEvent({
                        category: "job-details",
                        action: "download-template",
                        name: file.fileName,
                        documentTitle: DOCUMENT_TITLE,
                        customDimensions: [
                          {
                            id: 1,
                            value: "job-details",
                          },
                        ],
                      });
                    }}
                  />
                </>
              )}
            </div>
          </Card>
          {job.status === JobStatus.succeeded && (
            <Card className="w-full gap-ld-8">
              <div className="flex flex-col justify-start items-start w-full gap-ld-8">
                <LdTypo variant="h5">Results</LdTypo>

                {!resultFiles.length && isLoading && (
                  <div className="flex w-full flex-row justify-center items-center gap-ld-4">
                    <LdLoading style={{ "--ld-loading-size": "3rem" }} />
                  </div>
                )}
                <LdSelect
                  onLdchange={(e) => setSelectedSequence(e.detail[0])}
                  className="w-full"
                >
                  {job.proteins?.map((protein) => (
                    <LdOption
                      key={protein.proteinName}
                      selected={protein.proteinName === selectedSequence}
                      value={protein.proteinName}
                    >
                      {protein.proteinName}
                    </LdOption>
                  ))}
                </LdSelect>
                {Object.values(resultFiles).length > 0 && (
                  <>
                    {selectedSequence && (
                      <>
                        <div className="grid grid-cols-2">
                          {coverageImage && (
                            <img
                              key={coverageImage.fileName}
                              src={coverageImage.downloadUrl}
                              className={`w-auto max-h-[40rem]`}
                            />
                          )}
                          {plddtImage && (
                            <img
                              key={plddtImage.fileName}
                              src={plddtImage.downloadUrl}
                              className={`w-auto max-h-[40rem]`}
                            />
                          )}
                          {rankingImage && (
                            <div className="col-span-2">
                              <img
                                key={rankingImage.fileName}
                                src={rankingImage.downloadUrl}
                                className={`w-auto h-[10rem] mx-auto`}
                              />
                            </div>
                          )}
                          {/* {_.orderBy(
                            resultImages[sanatizeString(selectedSequence)],
                            "fileSize"
                          ).map((image, index) => (
                            <img
                              key={image.fileName}
                              src={image.downloadUrl}
                              className={`w-auto max-h-[40rem] ${
                                index ===
                                  resultImages[sanatizeString(selectedSequence)]
                                    .length -
                                    1 &&
                                index % 2 === 0 &&
                                "col-span-2"
                              }`}
                            />
                          ))} */}
                        </div>
                        <ResultsList
                          files={resultFiles[sanatizeString(selectedSequence)]}
                          proteins={job.proteins}
                          on3dView={setSelectedFile}
                        />
                      </>
                    )}
                    {resultArchive && (
                      <LdButton
                        href={resultArchive.downloadUrl}
                        target="_blank"
                        className="pt-ld-8"
                      >
                        <LdIcon name="download" />
                        Download all Results
                      </LdButton>
                    )}
                  </>
                )}
              </div>
            </Card>
          )}
          {job.auditTrail && (
            <Card className="w-full gap-ld-8">
              <div className="flex flex-col justify-start items-start w-full gap-ld-8">
                <LdTypo variant="h5">Status</LdTypo>
                <AuditTrailList trail={job.auditTrail} />
              </div>
            </Card>
          )}
        </div>
      </div>
      <DeleteJobModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onDelete={handleDeleteJob}
      />
      <ProteinViewerModal
        open={selectedFile !== undefined}
        onClose={() => setSelectedFile(undefined)}
        file={selectedFile}
      />
    </AppLayout>
  );
};

export default Job;
