import React, { FC, useState } from "react";
import {
  LdBadge,
  LdButton,
  LdIcon,
  LdModal,
  LdTypo,
} from "@emdgroup-liquid/liquid/dist/react";
import { ComponentProps } from "types/app";
import UploadDropzone from "components/UploadDropZone";
import type { Accept, FileError, FileRejection } from "react-dropzone";

interface Props extends ComponentProps {
  open: boolean;
  multiple?: boolean;
  onUpload: (files: File[]) => void;
  onCancel: () => void;
  accept?: Accept;
  validator?: <T extends File>(file: T) => FileError | FileError[] | null;
}

const UploadFileModal: FC<Props> = ({
  multiple = false,
  open,
  onUpload,
  onCancel,
  accept,
  children,
  validator,
}) => {
  const [_files, setFiles] = useState<Record<string, File>>({});
  const [errors, setErrors] = useState<string[]>([]);
  const files = Object.values(_files);

  const addFiles = (files: File[]) => {
    setFiles((state) =>
      files.reduce((prev, curr) => ({ ...prev, [curr.name]: curr }), state)
    );
  };

  const handleReject = (rejections: FileRejection[]) => {
    console.log({ rejections });
    const errorMessages = rejections.map(
      (rejection) =>
        `File ${rejection.file.name} is not valid: ${rejection.errors[0].message}`
    );
    setErrors(errorMessages);
  };

  const removeFile = (fileName: string) => {
    setFiles((state) => {
      const copy = { ...state };
      delete copy[fileName];
      return copy;
    });
  };

  return (
    <LdModal open={open} onLdmodalclosed={onCancel}>
      <LdTypo slot="header">Upload File</LdTypo>
      {children}
      {files.length > 0 && (
        <div className="flex flex-col w-full divide-y divide-sensitive-grey-darkest">
          {files.map((file) => (
            <div
              key={file.name}
              className="w-full flex flex-row items-center gap-ld-4 py-ld-4 justify-between"
            >
              <div className="flex break-all">{file.name}</div>
              <div className="flex">
                <LdButton
                  mode="danger-ghost"
                  onClick={() => removeFile(file.name)}
                >
                  <LdIcon name="bin" />
                </LdButton>
              </div>
            </div>
          ))}
        </div>
      )}
      {errors.map((err) => (
        <LdTypo key={err}>
          <LdBadge
            icon="attention"
            size="lg"
            style={{ "--ld-badge-bg-col": "var(--ld-thm-error)" }}
            className="mr-ld-4"
          />
          {err}
        </LdTypo>
      ))}
      {(multiple || !files.length) && (
        <UploadDropzone
          className="my-ld-16"
          onDrop={addFiles}
          onReject={handleReject}
          accept={accept}
          validator={validator}
        />
      )}

      <LdButton
        slot="footer"
        mode="danger-ghost"
        onClick={onCancel}
        className="my-ld-8"
      >
        Cancel
      </LdButton>
      <LdButton
        className="my-ld-8"
        slot="footer"
        onClick={() => onUpload(files)}
      >
        Upload
      </LdButton>
    </LdModal>
  );
};

export default UploadFileModal;
