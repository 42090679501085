import React, { FC } from "react";
import { LdButton, LdModal, LdTypo } from "@emdgroup-liquid/liquid/dist/react";
import { ComponentProps } from "types/app";

interface Props extends ComponentProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteJobModal: FC<Props> = ({ open, onClose, onDelete }) => {
  return (
    <LdModal open={open} onLdmodalclosed={onClose}>
      <LdTypo slot="header">Delete Job</LdTypo>
      <LdTypo>
        Do you really want to delete this Job? This action can&apos;t be undone.
      </LdTypo>
      <LdButton
        slot="footer"
        mode="ghost"
        onClick={onClose}
        className="my-ld-8"
      >
        Nevermind
      </LdButton>
      <LdButton
        className="my-ld-8"
        slot="footer"
        mode="danger"
        onClick={onDelete}
      >
        Delete
      </LdButton>
    </LdModal>
  );
};

export default DeleteJobModal;
