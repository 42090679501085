import React from "react";
import { JobStatusEntry } from "@alphafold/types";
import { ComponentProps } from "types/app";
import {
  LdTable,
  LdTableBody,
  LdTableCell,
  LdTableHead,
  LdTableHeader,
  LdTableRow,
} from "@emdgroup-liquid/liquid/dist/react";
import moment from "moment";

interface Props extends ComponentProps {
  trail: JobStatusEntry[];
}

const AuditTrailList: React.FC<Props> = ({ trail }) => {
  return (
    <LdTable className="w-full">
      <LdTableHead>
        <LdTableHeader>Changed At</LdTableHeader>
        <LdTableHeader>Status</LdTableHeader>
        <LdTableHeader>Message</LdTableHeader>
      </LdTableHead>
      <LdTableBody>
        {trail.map(({ status, createdAt, message }) => (
          <LdTableRow key={`${status}_${createdAt}`}>
            <LdTableCell>{moment(createdAt).format("l LTS")}</LdTableCell>
            <LdTableCell>{status}</LdTableCell>
            <LdTableCell>{message}</LdTableCell>
          </LdTableRow>
        ))}
      </LdTableBody>
    </LdTable>
  );
};

export default AuditTrailList;
