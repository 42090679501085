import { FC } from "react";

import { Head } from "./Head";

import "./style.scss";
import { ComponentProps } from "types/app";
import PoweredBy from "./PoweredBy";

const isMd = window.innerWidth > 500;

interface Props extends ComponentProps {
  actionComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
}

const Sidebar: FC<Props> = ({
  className,
  children,
  footerComponent,
  actionComponent,
}) => {
  return (
    <div className={className} id="sidebar">
      <div
        className={`flex flex-col h-full bg-vibrant-cyan transition-bounds duration-150 ease-out overflow-hidden ${
          isMd ? "w-56" : "w-20"
        }`}
      >
        <div className="">
          <Head isCollapsed={!isMd} />
        </div>
        <div className="flex-grow">{children}</div>
        <div className="">{actionComponent}</div>
        <div>{footerComponent}</div>
        <PoweredBy />
      </div>
    </div>
  );
};

export default Sidebar;
