import React, { FC } from "react";
import {
  LdAccordion,
  LdAccordionPanel,
  LdAccordionSection,
  LdAccordionToggle,
  LdButton,
} from "@emdgroup-liquid/liquid/dist/react";
import {
  // eslint-disable-next-line import/named
  LdAccordionSectionCustomEvent,
} from "@emdgroup-liquid/liquid";
import { File, Job } from "@alphafold/types";
import { ComponentProps } from "types/app";

import Card from "components/Card";
import JobDetails from "pages/Job/components/JobDetails";
import FileList from "pages/Job/components/FileList";

import { getFileLabel } from "util/files";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

interface JobListItemProps extends ComponentProps {
  job: Job;
  inputFiles?: File[];
  onClick?: (jobId: string) => void;
  onGetLoadInput: (jobId: string) => void;
  onDeleteJob?: (jobId: string) => void;
}

const JobListItem: FC<JobListItemProps> = ({
  job,
  inputFiles = [],
  onClick,
  onGetLoadInput,
  onDeleteJob,
}) => {
  const { trackEvent } = useMatomo();

  const DOCUMENT_TITLE = "Job List";

  const handleToggleInputs = (
    event: LdAccordionSectionCustomEvent<boolean>
  ) => {
    event.stopPropagation();
    if (event.detail && job.jobId) {
      trackEvent({
        category: "job-details",
        action: "expand-input",
        name: job.jobId,
        documentTitle: DOCUMENT_TITLE,
        customDimensions: [
          {
            id: 1,
            value: "job-list",
          },
        ],
      });
      onGetLoadInput(job.jobId);
    }
  };
  // const handleToggleResults = (
  //   event: LdAccordionSectionCustomEvent<boolean>
  // ) => {
  //   event.stopPropagation();
  //   if (event.detail && job.jobId) {
  //     trackEvent({
  //       category: "job-details",
  //       action: "expand-results",
  //       name: job.jobId,
  //       documentTitle: DOCUMENT_TITLE,
  //       customDimensions: [
  //         {
  //           id: 1,
  //           value: "job-list",
  //         },
  //       ],
  //     });
  //     onGetResults(job.jobId);
  //   }
  // };

  return (
    <Card
      className="w-full"
      onClick={() => job.jobId && onClick && onClick(job.jobId)}
    >
      <JobDetails job={job} />
      <LdAccordion className="w-full my-ld-16" tone="dark" rounded>
        <LdAccordionSection
          onClick={(e) => e.stopPropagation()}
          onLdaccordionchange={handleToggleInputs}
        >
          <LdAccordionToggle>Input Files</LdAccordionToggle>
          <LdAccordionPanel>
            <div className="gap-4 px-ld-16 py-ld-8">
              <FileList
                files={inputFiles}
                labelExtractor={getFileLabel}
                onFileDownload={(file) => {
                  trackEvent({
                    category: "job-details",
                    action: "download-input",
                    name: file.fileName,
                    documentTitle: DOCUMENT_TITLE,
                    customDimensions: [
                      {
                        id: 1,
                        value: "job-list",
                      },
                    ],
                  });
                }}
              />
            </div>
          </LdAccordionPanel>
        </LdAccordionSection>
        {/* {job.status === JobStatus.finished && (
          <LdAccordionSection
            onClick={(e) => e.stopPropagation()}
            onLdaccordionchange={handleToggleResults}
          >
            <LdAccordionToggle>Results</LdAccordionToggle>
            <LdAccordionPanel>
              <div className="gap-4 px-ld-16 py-ld-8">
                <FileList
                  files={resultFiles}
                  labelExtractor={getFileLabel}
                  onFileDownload={(file) => {
                    trackEvent({
                      category: "job-details",
                      action:
                        file.fileName &&
                        file.fileName?.indexOf("_ranked_0.pdb") > -1
                          ? "download-best-guess"
                          : "download-full-results",
                      name: file.fileName,
                      documentTitle: DOCUMENT_TITLE,
                      customDimensions: [
                        {
                          id: 1,
                          value: "job-list",
                        },
                      ],
                    });
                  }}
                />
              </div>
            </LdAccordionPanel>
          </LdAccordionSection>
        )} */}
      </LdAccordion>
      <div className="flex w-full flex-row justify-end gap-ld-8">
        {onDeleteJob && (
          <LdButton
            size="sm"
            mode="danger"
            onClick={(e) => {
              e.stopPropagation();
              trackEvent({
                category: "job-details",
                action: "delete-job",
                name: job.jobId,
                documentTitle: DOCUMENT_TITLE,
                customDimensions: [
                  {
                    id: 1,
                    value: "job-list",
                  },
                ],
              });
              job.jobId && onDeleteJob(job.jobId);
            }}
          >
            Delete Job
          </LdButton>
        )}
      </div>
    </Card>
  );
};

export default JobListItem;
