import { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Theme } from "@liquid-design/liquid-design-react";
import { ThemeProvider } from "@mui/material/styles";
import { MatomoProvider } from "@jonkoops/matomo-tracker-react";

import { UserContextProvider } from "services/auth";
import Pages from "pages";

import store from "store";
import { instance } from "services/matomo";

import theme from "assets/styles/material";
const App: FC = () => {
  return (
    <MatomoProvider value={instance}>
      <UserContextProvider
        domainHint="merckgroup.com"
        clientId={process.env.REACT_APP_CLIENT_ID || "I2U5fnSCcf6LPQtCNKQGBw"}
      >
        <Provider store={store}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <Theme
                className="inset-0 flex flex-col max-h-full"
                themeName="richBlue"
              >
                <Pages />
              </Theme>
            </ThemeProvider>
          </BrowserRouter>
        </Provider>
      </UserContextProvider>
    </MatomoProvider>
  );
};

export default App;
