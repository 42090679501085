import { File } from "@alphafold/types";
import {
  LdButton,
  LdIcon,
  LdLink,
  LdLoading,
} from "@emdgroup-liquid/liquid/dist/react";
import { FC, useMemo } from "react";
import { ComponentProps, FileStatus } from "types/app";
import { formatFileSize, getFileExtension } from "util/files";

interface Props extends ComponentProps {
  file: File;
  status?: FileStatus;
  onFileDownload?: (file: File) => void;
  onFilePreview?: (file: File) => void;
}

const FileInfo: FC<Props> = ({
  file,
  status,
  className,
  onFileDownload,
  onFilePreview,
}) => {
  const icon = () => {
    let iconName = "documents";
    if (!file.fileName) return iconName;
    let extension = getFileExtension(file.fileName)?.toLowerCase();
    switch (extension) {
      case "zip":
        iconName = "zip";
        break;
      case "pdf":
        iconName = "pdf";
        break;
      case "jpg":
      case "png":
      case "jpeg":
        iconName = "jpeg";
        break;
    }
    return iconName;
  };
  const color = () => {
    switch (status) {
      case FileStatus.invalid:
        return "text-rich-red";
      case FileStatus.uploading:
      case FileStatus.validating:
        return "text-vibrant-yellow";
      case FileStatus.valid:
        return "text-vibrant-green";
      default:
        return "";
    }
  };
  const isBusy = useMemo(() => {
    return (
      status &&
      [FileStatus.uploading, FileStatus.validating].indexOf(status) > -1
    );
  }, [status]);
  const { fileName, downloadUrl, fileSize } = file;
  return (
    <div className={`flex flex-row items-center gap-ld-8 ${className}`}>
      <LdIcon slot="trigger" name={icon()} className={color()} />
      <LdLink
        href={downloadUrl}
        target="_blank"
        onClick={() => onFileDownload && onFileDownload(file)}
      >
        {fileName} ({fileSize && formatFileSize(fileSize, 2)})
      </LdLink>
      {onFilePreview && (
        <LdButton onClick={() => onFilePreview(file)}>View</LdButton>
      )}
      {isBusy && <LdLoading />}
    </div>
  );
};

export default FileInfo;
