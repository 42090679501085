import * as React from "react";
import Navigation from "./components/Navigation";

import "../components/styles.scss";
import {
  LdLink,
  LdNotice,
  LdNotification,
} from "@emdgroup-liquid/liquid/dist/react";

type Props = {
  children?: React.ReactNode;
};

const AppLayout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <LdNotification placement="top" />
      <div className="fixed flex flex-col items-stretch w-full h-screen overflow-hidden bg-sensitive-grey text-rich-blue-darkest">
        <div className="flex flex-grow h-full overflow-auto">
          <Navigation className="h-full" />
          <main className="flex-grow h-full min-h-screen overflow-auto">
            {process.env.REACT_APP_ENVIRONMENT === "dev" && (
              <div className="w-full p-ld-8">
                <LdNotice
                  headline="Your currently using the Development instance"
                  mode="warning"
                >
                  <strong>
                    Access to this instance will be removed, starting Nov. 15th.
                  </strong>
                  <br />
                  Please start using the production environment:{" "}
                  <LdLink href="https://protein.p.uptimize.merckgroup.com/">
                    https://protein.p.uptimize.merckgroup.com/
                  </LdLink>
                </LdNotice>
              </div>
            )}
            {children}
          </main>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
