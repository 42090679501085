export const extractQuery = (searchTerm: string): string[] => {
  return searchTerm
    .split(" ")
    .map((x) => x.toLowerCase())
    .filter((x) => x);
};

export const filterByTerm = (string: string, searchTerm?: string): boolean => {
  if (!searchTerm) return true;

  const query = extractQuery(searchTerm);
  return query.every((queryItem) => {
    return string.toLowerCase().includes(queryItem);
  });
};
