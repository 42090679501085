export interface ComponentProps {
  children?: JSX.Element | JSX.Element[] | null | any;
  className?: string;
  [key: string]: any;
}

export enum StateStatus {
  idle = "idle",
  loading = "loading",
  succeeded = "succeeded",
  failed = "failed",
}

export enum FileStatus {
  uploading = "uploading",
  validating = "validating",
  valid = "valid",
  invalid = "invalid",
}

export interface Feedback {
  subject?: string;
  message?: string;
}

export interface BugFormData {
  title?: string;
  stepsToReproduce?: string;
  expectedResult?: string;
  systemInformation?: string;
  attachment?: File;
}

export const AdvancedParametersLabels: Record<
  string,
  { label: string; description: string; values: Array<number> }
> = Object.freeze({
  "num-models": {
    label: "No. of models",
    description:
      "Reducing the number of models speeds up the prediction but results in lower quality.",
    values: [1, 2, 3, 4, 5],
  },
  "num-recycle": {
    label: "Prediction recycles",
    description:
      "Increasing recycles can improve the prediction quality but slows down the prediction.",
    values: [1, 3, 6, 12, 24, 48],
  },
  "num-relax": {
    label: "Structures to relax",
    description:
      "Specify how many of the top ranked structures to relax using OpenMM/Amber. Typically, relaxing the top-ranked prediction is enough and speeds up the runtime.",
    values: [0, 1, 5],
  },
  "relax-max-iterations": {
    label: "Iterations for relaxation",
    description:
      "AlphaFold2 sets this to unlimited (0), however, we found that this can lead to very long relaxation times for some inputs.",
    values: [0, 200, 2000],
  },
});
