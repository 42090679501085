import React, { useRef, useState } from "react";
import { LdIcon, LdTypo } from "@emdgroup-liquid/liquid/dist/react";

import { ReactComponent as LogoutIcon } from "assets/static/images/icons/icon-logout.svg";
import { ReactComponent as OptionsIcon } from "assets/static/images/icons/icon-options.svg";

import "./style.scss";
import { useUser } from "services/auth";
import { useNavigate } from "react-router-dom";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";

interface Props {
  className?: string;
  isCollapsed?: boolean;
}

const User: React.FC<Props> = ({ isCollapsed, className }) => {
  const { info, logout } = useUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const userLabel = info?.email;

  const open = Boolean(anchorEl);

  const menuRef = useRef(null);

  const openMenu = () => {
    setAnchorEl(menuRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <div
        className={`h-px bg-sensitive-grey${
          info ? " opacity-25" : " opacity-0"
        }`}
      />
      <div
        onClick={openMenu}
        className={`flex relative group/user focus:outline-none cursor-pointer ${
          !isCollapsed ? "justify-start" : "justify-center"
        } px-6 py-4`}
      >
        <div
          className={`absolute w-full h-full left-0 top-0 transition duration-100 ease-in bg-transparent opacity-10 group-hover/user:bg-rich-blue-darkest`}
        />
        {!isCollapsed && (
          <div
            data-test="sidebar-user-label"
            className="flex-grow -mt-px font-bold text-white truncate whitespace-no-wrap"
          >
            {!info ? "" : userLabel}
          </div>
        )}
        {info && (
          <LdIcon className="text-white rotate-90" ref={menuRef}>
            <OptionsIcon />
          </LdIcon>
        )}
      </div>
      <div
        className={`h-px bg-sensitive-grey${
          info ? " opacity-25" : " opacity-0"
        }`}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuList>
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <LdIcon>
                <LogoutIcon />
              </LdIcon>
            </ListItemIcon>
            <ListItemText>
              <LdTypo>Logout</LdTypo>
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigate("/user")}>
            <ListItemIcon>
              <LdIcon name="settings" />
            </ListItemIcon>
            <ListItemText>
              <LdTypo>Settings</LdTypo>
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
};

export default User;
