/* eslint-disable import/named */
import React, { useRef, useState, useEffect } from "react";
import {
  LdInputCustomEvent,
  LdSelectCustomEvent,
} from "@emdgroup-liquid/liquid";
import {
  LdButton,
  LdIcon,
  LdInput,
  LdSelect,
  LdOption,
} from "@emdgroup-liquid/liquid/dist/react";
import { ComponentProps } from "types/app";

interface Props extends ComponentProps {
  searchQuery?: string;
  order?: "asc" | "desc";
  sortByValue?: string;
  sortOptions: { label: string; value: string }[];
  onQueryChange: (query?: string) => void;
  onSortChange: (sortOption: { label: string; value: string }) => void;
  onOrderChange: (orderBy: "asc" | "desc") => void;
}

const FilterBar: React.FC<Props> = ({
  order,
  searchQuery,
  sortOptions,
  sortByValue,
  onQueryChange,
  onSortChange,
  onOrderChange,
}) => {
  const inputRef = useRef<HTMLLdInputElement>(null);
  const [queryState, setQueryState] = useState(searchQuery);
  const [orderState, setOrderState] = useState<"asc" | "desc">(order || "desc");

  const handleSearch = React.useCallback(
    (event: LdInputCustomEvent<string>) => {
      setQueryState((event.target as HTMLLdInputElement).value);
    },
    []
  );
  const handleChangeSortBy = React.useCallback(
    (event: LdSelectCustomEvent<string[]>) => {
      const selected = sortOptions.find(
        ({ value }) => value === event.detail[0]
      );
      if (selected) onSortChange(selected);
    },
    [sortOptions]
  );

  const handleChangeOrder = React.useCallback(() => {
    setOrderState((state) => (state === "asc" ? "desc" : "asc"));
  }, []);

  const clearSearchBar = () => {
    setQueryState(undefined);
  };
  useEffect(() => {
    if (queryState !== searchQuery) onQueryChange(queryState);
  }, [queryState, onQueryChange]);

  useEffect(() => {
    if (queryState !== searchQuery) setQueryState(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (orderState !== order) onOrderChange(orderState);
  }, [orderState, onOrderChange]);

  return (
    <div className="flex items-center">
      <LdInput
        className="flex-grow mr-4"
        placeholder="Search..."
        size="sm"
        ref={inputRef}
        onLdinput={handleSearch}
        value={queryState}
      >
        <LdButton slot="start" size="sm" aria-label="Submit" mode="ghost">
          <LdIcon name="magnifier" />
        </LdButton>
        <LdButton
          slot="end"
          size="sm"
          aria-label="Clear"
          mode="ghost"
          type="button"
          onClick={clearSearchBar}
        >
          <LdIcon name="cross" />
        </LdButton>
      </LdInput>
      <div className="flex items-center justify-end">
        <LdSelect
          onLdinput={handleChangeSortBy}
          placeholder="Sort by"
          mode="ghost"
          size="sm"
        >
          {sortOptions.map(({ label, value }) => (
            <LdOption
              key={value}
              value={value}
              selected={sortByValue === value}
            >
              {label}
            </LdOption>
          ))}
          <LdIcon name="arrow-up-n-down" slot="icon" />
        </LdSelect>
        <LdButton
          data-tip={order === "asc" ? "ascending" : "descending"}
          size="sm"
          mode="ghost"
          onClick={handleChangeOrder}
          type="button"
        >
          <LdIcon name={order === "asc" ? "arrow-up" : "arrow-down"} />
        </LdButton>
      </div>
    </div>
  );
};

export default FilterBar;
