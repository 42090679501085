import React, { MouseEventHandler } from "react";
import { ComponentProps } from "types/app";
import "./styles.scss";

interface CardProps extends ComponentProps {
  className?: string;
  onClick?: MouseEventHandler;
}

const Card: React.FC<CardProps> = ({ children, className = "", ...props }) => (
  <div
    {...props}
    className={`flex flex-wrap flex-col items-center justify-center max-w-full card
       p-8 bg-white rounded-md overflow-visible ${className} ${
      props.onClick !== undefined && "clickable"
    }`}
  >
    {children}
  </div>
);

export default Card;
