import { useMemo } from "react";
import { Job, JobStatus } from "@alphafold/types";
import moment from "moment";
import { formatDuration } from "util/date";

const useStatusTime = (job: Job) => {
  const changedTime = useMemo(() => {
    if (!job.auditTrail) {
      return job.changedAt;
    }
    switch (job.status) {
      case JobStatus.queued:
      case JobStatus.running:
      case JobStatus.finished:
        return job.auditTrail.find(({ status }) => status === job.status)
          ?.createdAt;
      default:
        return job.changedAt;
    }
  }, [job]);

  const operationDuration = useMemo(() => {
    const defaultValue = moment(job.changedAt).diff(
      moment(job.createdAt),
      "second"
    );
    switch (job.status) {
      case JobStatus.queued:
      case JobStatus.running:
        return moment().diff(moment(changedTime), "seconds");
      case JobStatus.finished:
        if (job.runningTime !== undefined) return job.runningTime;
        return defaultValue;
      default:
        return defaultValue;
    }
  }, [job, changedTime]);

  const changedTimeString = useMemo(() => {
    const duration = moment.duration(operationDuration, "second");
    switch (job.status) {
      case JobStatus.queued:
        return `queued for ${formatDuration(duration).durationString}`;
      case JobStatus.running:
        return `running for ${formatDuration(duration).durationString}`;
      case JobStatus.finished:
        return `ran for ${formatDuration(duration).durationString}`;
    }
  }, [job, operationDuration]);

  return {
    changedTime,
    operationDuration,
    changedTimeString,
  };
};

export { useStatusTime as default };
