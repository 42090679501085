import React from "react";

import AppLayout from "layout/AppLayout";
import UserGuideMarkdown from "./components/UserGuideMarkdown";

const Userguide: React.FC = () => {
  return (
    <AppLayout>
      <div
        id="upload"
        className="relative flex max-w-[1024px] mx-auto gap-ld-16 pt-ld-32 justify-center"
      >
        <div className="relative flex flex-col items-start w-full mt-ld-16">
          <UserGuideMarkdown />
        </div>
      </div>
    </AppLayout>
  );
};

export default Userguide;
