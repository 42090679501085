import { LdButton, LdTypo } from "@emdgroup-liquid/liquid/dist/react";
import { useUser } from "services/auth";

import { LogoMicrosoft } from "components/Icons";
// import useUser from "hooks/useUser";
import React, { useEffect } from "react";

import logo from "assets/static/images/uptimize_logo.png";
import logoBig from "assets/static/images/vlb_ULwsEhayE5shWhE7s.png";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

const Login = () => {
  const { login } = useUser();

  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: "Login",
    });
  }, []);

  return (
    <div className="fixed flex flex-col items-stretch w-full h-screen overflow-hidden bg-sensitive-grey text-rich-blue-darkest">
      <div className="flex flex-grow h-full overflow-auto flex-col">
        <header className="w-full bg-rich-blue">
          <div className="relative flex max-w-[1024px] min-h-[10rem] w-full mx-auto flex-col">
            <div className="h-[20rem]relative flex justify-center bg-rb bg-no-repeat bg-right relative transition-colors w-full">
              <div className="absolute w-1/2 right-0 object-cover max-h-full min-h-full md:block hidden md:block">
                <svg
                  className="absolute bottom-0 min-h-full min-w-fit-content text-vc w-full h-full"
                  viewBox="-250 -20 700 250"
                  preserveAspectRatio="xMidYMid slice"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <mask id="space-header-image-mask">
                      <path
                        d="M780.132 272.46C812.391 228.667 806.353 222.934 802.857 201.117C799.36 179.459 726.42 -20.5553 726.42 -20.5553C716.09 -54.1565 706.873 -72.7882 680.335 -76.9287L450.865 -114.511C424.327 -118.652 385.87 -107.663 365.529 -89.987L44.5254 186.785C24.1846 204.302 19.894 236.788 35.1496 259.083L163.551 447.472C178.648 469.608 212.973 490.788 239.511 494.45L543.034 535.536C569.732 539.04 601.038 522.319 612.797 498.113C612.797 497.954 747.873 316.253 780.132 272.46ZM955.889 -161.967C942.541 -180.917 939.363 -179.165 930.146 -180.599C920.929 -182.032 830.666 -179.165 830.666 -179.165C815.728 -179.484 806.988 -178.21 801.903 -168.018L756.295 -80.2729C751.051 -70.081 750.415 -53.0416 754.865 -42.5313L823.674 124.2C828.124 134.711 840.519 140.762 851.484 137.577L944.448 110.664C955.413 107.479 968.444 96.4914 973.529 86.1403L1030.42 -31.0657C1035.5 -41.4167 1032.8 -56.2267 1024.7 -64.3483C1024.54 -64.3483 969.238 -143.176 955.889 -161.967Z"
                        fill="white"
                      ></path>
                    </mask>
                  </defs>
                  <image
                    width="100%"
                    height="100%"
                    dominantBaseline="center"
                    preserveAspectRatio="xMidYMid slice"
                    xlinkHref={logoBig}
                    mask="url(#space-header-image-mask)"
                  ></image>
                </svg>
              </div>
              <div className="w-full max-w-6xl z-10 mt-12 md:mt-0 pt-6">
                <div role="presentation" className="flex items-center h-14">
                  <img
                    className="object-contain h-14"
                    src={logo}
                    alt="Uptimize Cockpit"
                  />
                </div>
                <h2 className="max-w-xl mt-6 mb-16 font-bold text-wht typo-h2 min-h-[4.5rem]">
                  <LdTypo variant="h1" className="text-white mt-ld-16">
                    AlphaFold
                  </LdTypo>
                </h2>
              </div>
            </div>
          </div>
        </header>

        <main className="flex-grow h-full overflow-auto">
          <div className="relative flex max-w-[1024px] mx-auto flex-col pt-ld-16 items-center">
            <LdTypo className="my-ld-16">
              Get ready to revolutionize your research with AlphaFold, the
              state-of-the-art protein folding prediction tool, now available as
              a self-service option on UPTIMIZE, Merck&lsquo;s Data & Analytics
              Ecosystem. With AlphaFold, you can easily and accurately predict
              the 3D structure of proteins, enabling you to better understand
              their functions and potential therapeutic targets.
            </LdTypo>
            <LdTypo className="my-ld-16">
              Merck employees can log in with their organisational accounts.
            </LdTypo>

            {login && (
              <LdButton mode="secondary" onClick={() => login()}>
                <LogoMicrosoft />
                Login with SSO*
              </LdButton>
            )}
            <LdTypo variant="body-xs" className="my-ld-16">
              *You need to have an active organizational account
            </LdTypo>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Login;
