import React from "react";
import { LdButton, LdTypo } from "@emdgroup-liquid/liquid/dist/react";
import { Link } from "react-router-dom";
import { ComponentProps } from "types/app";
import JobListItemSkelleton from "./List/JobListItemSkelleton";
import { ReactComponent as DNAIcon } from "assets/static/images/dna.svg";

interface Props extends ComponentProps {
  loading: boolean;
  searching?: boolean;
}

const Empty: React.FC<Props> = ({ searching, loading }) => {
  return (
    <div className="flex gap-4 flex-col items-center justify-start w-full px-ld-8 pb-ld-8 overflow-y-auto">
      {loading && <JobListItemSkelleton />}
      {!loading && (
        <div className="flex flex-col items-center text-center">
          <div className="mb-8 mt-16 items-center flex-col flex">
            <DNAIcon className="w-16 h-16" />
            <LdTypo variant="xh6">Your database is empty</LdTypo>
            {searching ? (
              <LdTypo variant="h4">No proteins found.</LdTypo>
            ) : (
              <LdTypo variant="h4">No proteins folded yet.</LdTypo>
            )}
          </div>
          {!searching && (
            <Link to="/new-job">
              <LdButton mode="highlight">+ start folding</LdButton>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default Empty;
