import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "store";
import FilterBar from "components/FilterBar";
import { setQuery, changeOrder, setSortyBy } from "services/jobs";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import _ from "lodash";

const JobSearchBar = () => {
  const sortOptions = [
    { value: "jobName", label: "Job Name" },
    { value: "createdAt", label: "Created At" },
    { value: "status", label: "Status" },
  ];
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const { sortBy, searchQuery, order } = useSelector((state) => state.jobs);

  const [searchTerm, setSearchTerm] = useState<string>();

  useEffect(() => {
    dispatch(setQuery(searchTerm));
    trackEvent({
      category: "job-list",
      action: "search-job",
      name: searchTerm,
    });
  }, [searchTerm]);

  const handleSearch = useMemo(() => {
    return _.debounce(setSearchTerm, 300);
  }, []);

  useEffect(() => {
    return handleSearch.cancel;
  }, []);

  const handleOrderChange = (orderValue: "asc" | "desc") => {
    trackEvent({
      category: "job-list",
      action: "change-order-by",
      name: orderValue,
    });
    dispatch(changeOrder(orderValue));
  };
  const handleSortByChange = (sortByOption: {
    value: string;
    label: string;
  }) => {
    trackEvent({
      category: "job-list",
      action: "change-sort-by",
      name: sortByOption.value,
    });
    dispatch(setSortyBy(sortByOption.value));
  };
  return (
    <div className="w-full px-ld-8 my-ld-16">
      <FilterBar
        sortOptions={sortOptions}
        searchQuery={searchQuery}
        sortByValue={sortBy}
        order={order}
        onQueryChange={handleSearch}
        onOrderChange={handleOrderChange}
        onSortChange={handleSortByChange}
      />
    </div>
  );
};

export default JobSearchBar;
