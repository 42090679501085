// import useUser from "hooks/useUser";
import React, { useEffect } from "react";
import { useUser } from "services/auth";

import { Routes, Route, Navigate } from "react-router-dom";
import { createUser, getUser } from "services/user";
import { listJobs } from "services/jobs";
import { useDispatch, useSelector } from "store";
import { ComponentProps } from "types/app";
import Dashboard from "./Dashboard";
import Job from "./Job";
import Login from "./Login";
import Upload from "./Upload";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Feedback from "./Feedback";
import Userguide from "./Userguide";
import { User } from "./User";
import NglViewer from "./NglViewer";

const PrivateRoute: React.FC<ComponentProps> = ({ children = null }) => {
  const { session } = useUser();
  if (!session) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const PublicRoute: React.FC<ComponentProps> = ({ children = null }) => {
  const { session } = useUser();
  if (session) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const Pages = () => {
  const { enableLinkTracking } = useMatomo();
  const dispatch = useDispatch();
  const { error } = useSelector(({ user }) => user);
  const { info } = useUser();

  useEffect(() => {
    if (info) {
      dispatch(getUser());
    }
  }, [info]);

  useEffect(() => {
    if (error && parseInt(error) === 404) {
      dispatch(createUser()).then(() => dispatch(listJobs()));
    }
  }, [error]);

  enableLinkTracking();
  return (
    <Routes>
      <Route index element={<Navigate to="/jobs" />} />
      <Route
        path="/jobs"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/jobs/:jobId"
        element={
          <PrivateRoute>
            <Job />
          </PrivateRoute>
        }
      />
      <Route
        path="/jobs/:jobId/viewer"
        element={
          <PrivateRoute>
            <NglViewer />
          </PrivateRoute>
        }
      />
      <Route
        path="/new-job"
        element={
          <PrivateRoute>
            <Upload />
          </PrivateRoute>
        }
      />
      <Route
        path="/user-guide"
        element={
          <PrivateRoute>
            <Userguide />
          </PrivateRoute>
        }
      />
      <Route
        path="/user"
        element={
          <PrivateRoute>
            <User />
          </PrivateRoute>
        }
      />
      <Route
        path="/feedback"
        element={
          <PrivateRoute>
            <Feedback />
          </PrivateRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
    </Routes>
  );
};

export default Pages;
