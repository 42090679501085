import {
  LdLabel,
  LdOption,
  LdSelect,
  LdTypo,
} from "@emdgroup-liquid/liquid/dist/react";
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
// eslint-disable-next-line import/named
import { LdSelectCustomEvent } from "@emdgroup-liquid/liquid";

import AppLayout from "layout/AppLayout";
import Card from "components/Card";
import { BugFormData, Feedback } from "types/app";
import FeedbackForm from "./components/FeedbackForm";
import BugTrackingForm from "./components/BugTrackingForm";
import { useFeedback } from "./hooks/useFeedback";

const FEEDBACK_TYPES: Record<string, string> = {
  feedback: "Feedback",
  bug: "Report Error",
};

var FeedbackPage = () => {
  const { trackPageView, trackEvent } = useMatomo();
  const navigate = useNavigate();
  const { sendFeedback, sendBug } = useFeedback();

  const [feedbackType, setFeedbackType] = useState("feedback");

  useEffect(() => {
    trackPageView({
      documentTitle: "User Guide",
    });
  }, []);

  const handleFeedbackTypeChange = useCallback(
    (event: LdSelectCustomEvent<string[]>) => {
      setFeedbackType(event.detail[0]);
    },
    []
  );

  const handleFeedback = (feedback: Feedback) => {
    trackEvent({
      category: "feedback",
      action: "submit-feedback",
    });
    sendFeedback(feedback);
    dispatchEvent(
      new CustomEvent("ldNotificationAdd", {
        detail: {
          content: "Thank you for your valuable Feedback",
          type: "info",
        },
      })
    );
    navigate("/");
  };

  const handleBug = (bug: BugFormData) => {
    trackEvent({
      category: "feedback",
      action: "submit-bug",
    });
    sendBug(bug);
    dispatchEvent(
      new CustomEvent("ldNotificationAdd", {
        detail: {
          content: "Thank you for your valuable Feedback",
          type: "info",
        },
      })
    );
    navigate("/");
  };

  const navigateBack = () => {
    navigate(-1);
  };
  return (
    <AppLayout>
      <div id="upload" className="relative flex max-w-[1024px] mx-auto">
        <div className="relative flex flex-col items-start w-full max-h-screen min-h-screen pt-8 pl-4">
          <div className="flex justify-start items-center mb-5 gap-ld-4 pt-ld-4">
            <Link to={`/projects`} className="flex hover:underline">
              <LdTypo variant="body-s">Feedback & Error Reporting</LdTypo>
            </Link>
          </div>
          <Card className=" w-full">
            <div className="flex w-full flex-col">
              <LdTypo variant="h5" className="mb-ld-8">
                Help us improve! Share your feedback or report bugs to enhance
                your experience.
              </LdTypo>
              <LdLabel className="my-ld-16">
                Type of Feedback
                <LdSelect onLdinput={handleFeedbackTypeChange}>
                  {Object.keys(FEEDBACK_TYPES).map((key) => (
                    <LdOption
                      key={key}
                      value={key}
                      selected={feedbackType === key}
                    >
                      {FEEDBACK_TYPES[key]}
                    </LdOption>
                  ))}
                </LdSelect>
              </LdLabel>
              <>
                {feedbackType === "feedback" && (
                  <FeedbackForm
                    onSubmit={handleFeedback}
                    onCancel={navigateBack}
                  />
                )}
                {feedbackType === "bug" && (
                  <BugTrackingForm
                    onSubmit={handleBug}
                    onCancel={navigateBack}
                  />
                )}
              </>
            </div>
          </Card>
        </div>
      </div>
    </AppLayout>
  );
};

export default FeedbackPage;
