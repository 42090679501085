import React from "react";
import { Protein } from "@alphafold/types";
import { ComponentProps } from "types/app";
import {
  LdTable,
  LdTableBody,
  LdTableCaption,
  LdTableCell,
  LdTableHead,
  LdTableHeader,
  LdTableRow,
  LdTableToolbar,
} from "@emdgroup-liquid/liquid/dist/react";

interface Props extends ComponentProps {
  proteins: Protein[];
  title?: string;
}

const ProteinList: React.FC<Props> = ({ proteins, title }) => {
  return (
    <LdTable className="w-full">
      <LdTableToolbar slot="toolbar">
        <LdTableCaption>{title}</LdTableCaption>
      </LdTableToolbar>
      <LdTableHead>
        <LdTableRow>
          <LdTableHeader>Name</LdTableHeader>
          <LdTableHeader>Sequence</LdTableHeader>
          <LdTableHeader>Sequence Length</LdTableHeader>
        </LdTableRow>
      </LdTableHead>
      <LdTableBody>
        {proteins.map((protein) => (
          <LdTableRow key={protein.proteinName}>
            <LdTableCell>{protein.proteinName}</LdTableCell>
            <LdTableCell>{protein.proteinSequence}</LdTableCell>
            <LdTableCell>{protein.sequenceLength}</LdTableCell>
          </LdTableRow>
        ))}
      </LdTableBody>
    </LdTable>
  );
};

export default ProteinList;
