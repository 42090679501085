import { File, Job } from "@alphafold/types";
import React, { FC } from "react";
import { ComponentProps } from "types/app";
import JobListItem from "./JobListItem";

interface JobListProps extends ComponentProps {
  jobs: Job[];
  onClick?: (jobId: string) => void;
  onGetInput: (jobId: string) => void;
  onGetResults: (url: string) => void;
  onDeleteJob?: (jobId: string) => void;
  inputFileSelecor: (jobId: string) => File[];
  resultFileSelecor: (jobId: string) => Record<string, File[]>;
}

const JobList: FC<JobListProps> = ({
  jobs,
  onClick,
  onGetInput,
  onGetResults,
  onDeleteJob,
  inputFileSelecor,
  resultFileSelecor,
}) => {
  return (
    <div className="flex gap-4 flex-col items-center justify-start w-full px-ld-8 pb-ld-8 overflow-y-auto">
      {jobs.map((job) => (
        <JobListItem
          key={job.jobId}
          job={job}
          inputFiles={job.jobId ? inputFileSelecor(job.jobId) : []}
          resultFiles={job.jobId ? resultFileSelecor(job.jobId) : {}}
          onClick={onClick}
          onGetResults={onGetResults}
          onDeleteJob={onDeleteJob}
          onGetLoadInput={onGetInput}
        />
      ))}
    </div>
  );
};

export default JobList;
