import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "./util/polyfill";

import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

import "./index.css";
import "@emdgroup-liquid/liquid/dist/css/liquid.global.css";
import { defineCustomElements } from "@emdgroup-liquid/liquid/dist/loader";
import reportWebVitals from "reportWebVitals";

// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
window.__LD_ASSET_PATH__ = window.location.origin + "/liquid/";

defineCustomElements();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
