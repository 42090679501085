import React from "react";
import { ComponentProps } from "types/app";

interface Props extends ComponentProps {
  icon: JSX.Element;
  label: string;
  isSelected: boolean;
  isCollapsed: boolean;
  onClick?: () => void;
}

const Item: React.FC<Props> = ({
  icon,
  label,
  isSelected,
  isCollapsed,
  onClick,
  ...props
}) => (
  <div
    {...props}
    className={`relative left-0 top-0 w-full h-16 group ${
      isSelected ? "font-extrabold cursor-default" : "cursor-pointer"
    }`}
    onClick={onClick}
  >
    <div
      className={`absolute w-full h-full left-0 top-0 transition duration-100 ease-in ${
        isSelected
          ? "bg-rich-blue-darkest opacity-25"
          : "bg-transparent opacity-10 group-hover:bg-rich-blue-darkest"
      }`}
    />
    <div
      className={`absolute bg-vibrant-yellow h-full${
        isSelected ? " w-1" : " w-0"
      }`}
    />
    <div
      className={`absolute top-0 left-0 flex w-full h-full px-6 py-5 text-white ${
        !isCollapsed ? "" : "justify-center"
      }`}
    >
      <div className="flex items-center justify-center w-5 max-h-full">
        {icon}
      </div>
      {!isCollapsed && (
        <div className="ml-3 whitespace-no-wrap transform translate-y-px">
          {label}
        </div>
      )}
    </div>
  </div>
);

export default Item;
