import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      light: "#57cbd7",
      main: "#2dbecd",
      dark: "#29abb9",
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#ffd35b",
      main: "#ffc832",
      dark: "#f3be2f",
      contrastText: "#ffcc00",
    },
    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    info: {
      light: "#3f87bf",
      main: "#0f69af",
      dark: "#0e5f9e",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

export { theme as default };
