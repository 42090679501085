import { useEffect, useRef } from "react";
import * as NGL from "ngl";

import AppLayout from "layout/AppLayout";
import { useLocation } from "react-router-dom";

const NglViewer = () => {
  const { state } = useLocation();

  const viewerRef = useRef(null);

  useEffect(() => {
    if (state.downloadUrl && viewerRef.current) {
      const stage = new NGL.Stage(viewerRef.current);
      stage.loadFile(state.downloadUrl, {
        ext: "pdb",
        defaultRepresentation: true,
      });
    }
  }, [state]);

  return (
    <AppLayout>
      <div
        id="job-details"
        className="relative flex-col flex max-w-[1024px] mx-auto gap-ld-16 justify-center"
      >
        <div className="relative flex flex-col items-start w-full gap-ld-16">
          <div ref={viewerRef} id="viewport" className="w-full h-[500px]" />
        </div>
      </div>
    </AppLayout>
  );
};

export default NglViewer;
