import React, { useEffect } from "react";
import {
  LdLabel,
  LdLoading,
  LdToggle,
  LdTypo,
} from "@emdgroup-liquid/liquid/dist/react";
import type { LdToggleCustomEvent } from "@emdgroup-liquid/liquid";
import AppLayout from "layout/AppLayout";
import Card from "components/Card";
import { useDispatch, useSelector } from "store";
import { changeSettings, getUser } from "services/user";

const Users = () => {
  const { user, status } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, []);

  const onNotificationChange = (ev: LdToggleCustomEvent<boolean>) => {
    dispatch(changeSettings({ enableNotifications: ev.detail }));
  };

  return (
    <AppLayout>
      <div
        id="upload"
        className="relative flex max-w-[1024px] mx-auto gap-ld-16 pt-ld-32 justify-center"
      >
        <div className="relative flex flex-col items-start w-full mt-ld-16">
          <Card className="w-full">
            {!user && status === "loading" && <LdLoading />}
            {user && (
              <div className=" flex-col w-full gap-ld-8 flex items-start justify-start">
                <LdTypo className="w-full flex">{user?.name}</LdTypo>
                <LdTypo variant="label-s">
                  Email: <span className="font-normal">{user?.email}</span>
                </LdTypo>
                <LdTypo variant="cap-m" className="mt-ld-16">
                  Settings
                </LdTypo>
                <LdLabel position="left">
                  Enable Notifications
                  <LdToggle
                    size="sm"
                    onLdchange={onNotificationChange}
                    checked={user?.enableNotifications}
                  />
                </LdLabel>
              </div>
            )}
          </Card>
        </div>
      </div>
    </AppLayout>
  );
};

export default Users;
