import React from "react";
import { LdTypo } from "@emdgroup-liquid/liquid/dist/react";

import logo from "assets/static/images/logo.png";

const PoweredBy = () => {
  return (
    <div className="px-6 py-4">
      <LdTypo className="text-white" variant="body-xs">
        powered by
      </LdTypo>
      <div className="flex flex-row items-center gap-ld-4">
        <img src={logo} className="h-ld-16" />
        <span className="text-white font-uptimize font-black text-xs">
          UPTIMIZE
        </span>
      </div>
    </div>
  );
};

export default PoweredBy;
