import React, { FC } from "react";
import { NavLink } from "react-router-dom";

import { LdIcon } from "@emdgroup-liquid/liquid/dist/react";
import { Sidebar } from "components";
import Item from "components/Sidebar/Item";
import { ComponentProps } from "types/app";
import { User } from "./User";
import { InfoIcon } from "components/Icons";

const isMd = window.innerWidth > 500;

const Navigation: FC<ComponentProps> = () => {
  return (
    <Sidebar footerComponent={<User isCollapsed={!isMd} />}>
      <>
        <NavLink to="/new-job">
          {({ isActive }) => (
            <Item
              data-test="sidebar-item-upload"
              label="Create Job"
              icon={<LdIcon name="upload" />}
              isCollapsed={!isMd}
              isSelected={isActive}
            />
          )}
        </NavLink>
        <NavLink to="/jobs">
          {({ isActive }) => (
            <Item
              data-test="sidebar-item-database"
              label="My Jobs"
              icon={<LdIcon name="dashboard" />}
              isCollapsed={!isMd}
              isSelected={isActive}
            />
          )}
        </NavLink>
        <NavLink to="/user-guide">
          {({ isActive }) => (
            <Item
              data-test="sidebar-item-database"
              label="User Guide"
              icon={
                <LdIcon>
                  <InfoIcon />
                </LdIcon>
              }
              isCollapsed={!isMd}
              isSelected={isActive}
            />
          )}
        </NavLink>
        <NavLink to="/feedback">
          <Item
            data-test="sidebar-item-feedback"
            label="Feedback & Issues"
            icon={<LdIcon name="conversation" />}
            isCollapsed={!isMd}
            isSelected={false}
          />
        </NavLink>
      </>
    </Sidebar>
  );
};

export default Navigation;
