import {
  LdLabel,
  LdInput,
  LdInputMessage,
  LdButton,
} from "@emdgroup-liquid/liquid/dist/react";
import useClientinfo from "hooks/useClientinfo";
import React, { FC, FormEventHandler, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { BugFormData } from "types/app";

interface Props {
  onSubmit: (arg0: BugFormData) => void;
  onCancel: () => void;
}

const BugTrackingForm: FC<Props> = ({ onSubmit, onCancel }) => {
  const { browser, device, engine, os } = useClientinfo();

  const systemInfo = useMemo(() => {
    try {
      return `browser: ${browser.name} (${browser.version})\nengine: ${
        engine.name
      } (${engine.version})\nos: ${os.name} (${
        os.version
      })\ndevice: ${JSON.stringify(device)}`;
    } catch {
      return undefined;
    }
  }, [browser, device, engine, os]);

  const { formState, register, setValue, trigger, clearErrors, watch, reset } =
    useForm<BugFormData>({
      mode: "onTouched",
      reValidateMode: "onChange",
      defaultValues: {
        systemInformation: systemInfo,
      },
    });

  const { errors } = formState;
  const [attachment, setAttachment] = useState<File>();
  const { title, stepsToReproduce, expectedResult, systemInformation } =
    watch();

  const handleScreenshotChange: FormEventHandler<HTMLLdInputElement> = async (
    event
  ) => {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      setAttachment(files[0]);
    }
  };

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const isValid = await trigger();
    if (!isValid) return;
    onSubmit({
      title,
      stepsToReproduce,
      expectedResult,
      systemInformation,
      attachment,
    });
    clearErrors();
    reset();
  };

  const handleCancel = () => {
    clearErrors();
    reset();
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-ld-16">
        <LdLabel className="w-full">
          Subject *
          <LdInput
            className="w-full"
            required
            onInput={(ev) =>
              setValue("title", (ev.target as HTMLLdInputElement).value)
            }
            value={title}
            {...register("title", {
              required: true,
            })}
          />
          {errors.title && (
            <LdInputMessage>This field is required.</LdInputMessage>
          )}
        </LdLabel>
        <LdLabel className="w-full">
          Steps to reproduce *
          <LdInput
            required
            className="w-full"
            onInput={(ev) =>
              setValue(
                "stepsToReproduce",
                (ev.target as HTMLLdInputElement).value
              )
            }
            multiline
            rows={3}
            value={stepsToReproduce}
            {...register("stepsToReproduce", {
              required: true,
            })}
          />
          {errors.stepsToReproduce && (
            <LdInputMessage>Steps to reproduce is required</LdInputMessage>
          )}
        </LdLabel>
        <LdLabel className="w-full">
          Expected behavior
          <LdInput
            className="w-full"
            onInput={(ev) =>
              setValue(
                "expectedResult",
                (ev.target as HTMLLdInputElement).value
              )
            }
            multiline
            rows={3}
            value={expectedResult}
            {...register("expectedResult", {
              required: false,
            })}
          />
          {errors.expectedResult && (
            <LdInputMessage>Expected behavior is required</LdInputMessage>
          )}
        </LdLabel>
        <LdLabel className="w-full">
          System Information
          <LdInput
            className="w-full"
            onInput={(ev) =>
              setValue(
                "systemInformation",
                (ev.target as HTMLLdInputElement).value
              )
            }
            multiline
            rows={3}
            value={systemInformation}
            {...register("systemInformation", {
              required: false,
            })}
          />
        </LdLabel>
        <LdLabel className="w-full">
          Attachment
          <LdInput
            className="w-full"
            onInput={handleScreenshotChange}
            type="file"
          />
        </LdLabel>
      </div>
      <div className="flex flex-row justify-end gap-ld-8 mt-ld-16">
        <LdButton type="button" onClick={handleCancel} mode="danger-secondary">
          Cancel
        </LdButton>
        <LdButton>Submit</LdButton>
      </div>
    </form>
  );
};

export default BugTrackingForm;
