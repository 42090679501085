/* eslint-disable import/named */
import { configureStore, AsyncThunk } from "@reduxjs/toolkit";
import jobsReducer from "../services/jobs";
import draftReducer from "../services/draft";
import userReducer from "../services/user";

const store = configureStore({
  reducer: { jobs: jobsReducer, draft: draftReducer, user: userReducer },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
export type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
export type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;

export default store;
