import { FC, useEffect, useMemo, useRef, useState } from "react";
import * as NGL from "ngl";
import {
  LdLabel,
  LdModal,
  LdOption,
  LdSelect,
  LdSlider,
  LdTypo,
} from "@emdgroup-liquid/liquid/dist/react";
import { ComponentProps } from "types/app";
import { File } from "@alphafold/types";
import FileInfo from "components/FileInfo";
import _ from "lodash";

interface Props extends ComponentProps {
  open: boolean;
  file?: File;
  onClose: () => void;
}

const NglRepresentations = ["backbone", "surface"];
const NglColor = ["default", "bfactor", "chainname", "element", "residueindex"];

const ProteinViewerModal: FC<Props> = ({ open, file, onClose }) => {
  const viewerRef = useRef(null);
  const modalRef = useRef(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [stage, setStage] = useState<NGL.Stage>();
  const [color, setColor] = useState<number>(0);
  const [representation, setRepresentation] = useState<string>("backbone");
  const [coloring, setColoring] = useState<string>("default");
  const [tooltipText, setTooltipText] = useState<string>();

  const handleClose = () => {
    stage?.removeAllComponents();
    onClose();
  };

  const colorString = useMemo(() => {
    return `rgb(${color}%,${color}%,${color}%)`;
  }, [color]);

  useEffect(() => {
    stage?.setParameters({
      backgroundColor: colorString,
    });
  }, [stage, colorString]);

  useEffect(() => {
    stage?.eachComponent((component) => {
      component.removeAllRepresentations();

      let atomCount; //, residueCount, instanceCount;
      const structure = (component as NGL.StructureComponent).structure;

      if (structure.biomolDict.BU1) {
        const assembly = structure.biomolDict.BU1;
        atomCount = assembly.getAtomCount(structure);
        // residueCount = assembly.getResidueCount(structure);
        // instanceCount = assembly.getInstanceCount();
        (component as NGL.StructureComponent).setDefaultAssembly("BU1");
      } else {
        atomCount = structure.getModelProxy(0).atomCount;
        // residueCount = structure.getModelProxy(0).residueCount;
        // instanceCount = 1;
      }

      let sizeScore = atomCount;

      const backboneOnly =
        structure.atomStore.count / structure.residueStore.count < 2;
      if (backboneOnly) {
        sizeScore *= 10;
      }

      let colorScheme = coloring === "default" ? "chainname" : coloring;
      let colorScale = "RdYlBu";
      let colorReverse = false;
      if (
        structure.getChainnameCount(new NGL.Selection("polymer and /0")) === 1
      ) {
        colorScheme = coloring === "default" ? "residueindex" : coloring;
        colorScale = "Spectral";
        colorReverse = true;
      }

      switch (representation) {
        case "backbone":
          // stage.defaultFileRepresentation(component);
          if (sizeScore > 250000) {
            component.addRepresentation("backbone", {
              colorScheme,
              colorScale,
              colorReverse,
              lineOnly: true,
            });
          } else if (sizeScore > 100000) {
            component.addRepresentation("backbone", {
              colorScheme,
              colorScale,
              colorReverse,
              quality: "low",
              disableImpostor: true,
              radiusScale: 2.0,
            });
          } else if (sizeScore > 80000) {
            component.addRepresentation("backbone", {
              colorScheme,
              colorScale,
              colorReverse,
              radiusScale: 2.0,
            });
          } else {
            component.addRepresentation("cartoon", {
              colorScheme,
              colorScale,
              colorReverse,
              radiusScale: 0.7,
              aspectRatio: 5,
              quality: "auto",
            });
            if (sizeScore < 50000) {
              component.addRepresentation("base", {
                colorScheme,
                colorScale,
                colorReverse,
                quality: "auto",
              });
            }
            component.addRepresentation("ball+stick", {
              sele: "ligand",
              colorScheme: coloring === "default" ? "element" : coloring,
              radiusScale: 2.0,
              aspectRatio: 1.5,
              bondScale: 0.3,
              bondSpacing: 0.75,
              quality: "auto",
            });
          }
          break;
        default:
          component.addRepresentation(representation as any, {
            colorScheme,
            colorScale,
            colorReverse,
          });
          component.autoView();
      }
    });
  }, [stage, representation, coloring]);

  useEffect(() => {
    if (!stage && viewerRef.current && open) {
      const _stage = new NGL.Stage(viewerRef.current, {
        backgroundColor: "none",
      });
      _stage.signals.hovered.add(function (pickingProxy: NGL.PickingProxy) {
        if (
          tooltipRef.current &&
          pickingProxy &&
          (pickingProxy.atom || pickingProxy.bond)
        ) {
          var atom = pickingProxy.atom || pickingProxy.closestBondAtom;
          var cp = pickingProxy.canvasPosition;
          setTooltipText(`ATOM: ${atom.qualifiedName()}`);
          tooltipRef.current!.style.bottom = `${cp.y + 70}px`;
          tooltipRef.current!.style.left = `${cp.x + 40}px`;
          tooltipRef.current!.style.opacity = "1";
        } else {
          tooltipRef.current!.style.opacity = "0";
        }
      });
      _stage.mouseControls.remove("hoverPick");
      setStage(_stage);
    }
  }, [open]);

  useEffect(() => {
    if (open && stage && file?.downloadUrl) {
      stage.loadFile(file.downloadUrl, {
        ext: "pdb",
        defaultRepresentation: true,
      });
    }
  }, [stage, file, open]);

  const tetherOptions = {
    bodyElement: modalRef.current,
    constraints: [{ to: "scrollParent" }],
  };

  return (
    <>
      <LdModal
        open={open && file !== undefined}
        ref={modalRef}
        onLdmodalclosed={handleClose}
        style={{ "--ld-modal-min-inline-size": "60rem" }}
      >
        <div className="flex flex-row w-full overflow-hidden">
          <div
            ref={viewerRef}
            id="viewport"
            className="flex w-full h-[500px]"
          />

          <div className="flex w-[15rem] flex-col gap-ld-8 px-ld-8">
            <LdTypo variant="cap-m">Control Panel</LdTypo>
            <LdLabel>
              Representation{" "}
              <LdSelect
                size="sm"
                onLdchange={(e) => setRepresentation(e.detail[0])}
                tetherOptions={tetherOptions}
              >
                {NglRepresentations.map((val) => (
                  <LdOption
                    key={`rep_option_${val}`}
                    value={val}
                    selected={val === representation}
                  >
                    {_.capitalize(val)}
                  </LdOption>
                ))}
              </LdSelect>
            </LdLabel>
            <LdLabel>
              Coloring{" "}
              <LdSelect
                size="sm"
                onLdchange={(e) => setColoring(e.detail[0])}
                tetherOptions={tetherOptions}
              >
                {NglColor.map((val) => (
                  <LdOption
                    key={`color_option_${val}`}
                    value={val}
                    selected={val === coloring}
                  >
                    {_.capitalize(val)}
                  </LdOption>
                ))}
              </LdSelect>
            </LdLabel>
            <LdLabel>
              Background{" "}
              <LdSlider
                size="sm"
                value={color}
                onLdchange={(e) => {
                  setColor(e.detail[0]);
                }}
              />
            </LdLabel>
          </div>
        </div>
        <div
          ref={tooltipRef}
          className="absolute card transition-opacity	p-ld-8 z-50"
        >
          <LdTypo variant="body-s">{tooltipText}</LdTypo>
        </div>
        <div slot="footer" className="break-all flex w-full justify-start">
          {file && <FileInfo file={file} />}
        </div>
      </LdModal>
    </>
  );
};

export default ProteinViewerModal;
